import _taggedTemplateLiteral from "/codebuild/output/src273884586/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
import { defaultPalette, sizes } from '@guryou/html-theme';
import styled from 'styled-components';
import propsToCss from '../utils/propsToCss';
export const Image = styled.img(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  ", ";\n"])), props => propsToCss(props));
export const ImageSmall = styled.img(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  max-width: 30px;\n  ", ";\n"])), props => propsToCss(props));
export const ImageThumbnail = styled.img(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-width: 100px;\n  ", ";\n"])), props => propsToCss(props));
export const ImageThumbnailRoundedCorners = styled.img(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 100px;\n  height: 100px;\n  border-radius: 15%;\n  ", ";\n"])), props => propsToCss(props));
export const ImageRoundThumbnail = styled.img(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 70px;\n  height: 70px;\n  border-radius: 50%;\n  ", ";\n"])), props => propsToCss(props));
export const ImageMedium = styled.img(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  max-width: ", "px;\n  width: auto;\n  height: auto;\n  display: block;\n  ", ";\n"])), sizes.small, props => propsToCss(props));
export const FullWidthImage = styled.img(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: block;\n  ", ";\n"])), props => propsToCss(props));
export const ImageLogo = styled.img(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  max-width: 170px;\n  &:hover {\n    cursor: pointer;\n  }\n  ", "\n"])), props => propsToCss(props));
export const Svg = styled.svg(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", ";\n"])), props => propsToCss(props));

// Obsolete
export const ProviderImageInfo = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  border: ", ";\n  background-image: ", ";\n  background-size: ", ";\n  background-repeat: no-repeat;\n  background-position: center center;\n  margin-bottom: 0.5rem;\n"])), props => props.width || '70px', props => props.height || '70px', props => props.borderRadius || '50%', props => props.activeStatus !== undefined && "2px solid ".concat(defaultPalette[props.activeStatus ? 'success' : 'error'].main), props => "url('".concat(props.bg, "')"), props => !props.bg.includes('http') ? '70% 70%' : 'cover');