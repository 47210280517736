import { archiveServiceApiCall, getAllServicesApiCall, getArchivedServicesApiCall, getProviderAllServicesApiCall, getPublicServicesApiCall, getServicesApiCall, getServicesCoursesEventsApiCall, getServicesSubServicesClassesApiRequest, publishAllServiceChildrenApiCall, reorderServicesApiCall, restoreServiceApiCall, saveServiceApiCall, toggleServiceStatusApiCall } from '@guryou/api-client-gql';
import { EntityTypes, ServiceTypes } from '@guryou/core';
import { resetState } from '../actions';
import { execute } from '../actions/actionWrapper';
import { Action, basicReducer } from '../core';
import { DEFAULT_END_DATE, DEFAULT_END_TIME, DEFAULT_START_DATE, DEFAULT_START_TIME } from '..';

// Types
export const SERVICES_GET_MANY = {
  entity: 'SERVICE',
  action: Action.GET
};
export const PROVIDER_ADD_SERVICE_RESOURCES = {
  entity: 'PROVIDER_SERVICE_ID',
  action: Action.CREATE
};
export const PROVIDER_ARCHIVE_SERVICE = {
  entity: 'SERVICE',
  action: Action.ARCHIVE
};
export const PROVIDER_ARCHIVED_SERVICES_GET_MANY = {
  entity: 'ARCHIVED_SERVICE',
  action: Action.GET
};
export const PROVIDER_SERVICES_GET_MANY = {
  entity: 'PROVIDER_SERVICE',
  action: Action.GET
};
export const PROVIDER_SERVICES_GET_MANY_ALL = {
  entity: 'PROVIDER_SERVICE',
  action: Action.GET_MANY
};
export const PROVIDER_SERVICE_ACTIVE_STATUS = {
  entity: 'SERVICE',
  action: Action.UPDATE
};
export const PROVIDER_SERVICE_TOGGLE_STATUS = {
  entity: 'SERVICE_STATUS',
  action: Action.UPDATE
};
export const PROVIDER_SERVICE_PUBLISH_ALL_CHILDREN = {
  entity: 'SERVICE_PUBLISH_ALL_CHILDREN',
  action: Action.UPDATE
};
export const PROVIDER_ADD_SERVICE = {
  entity: 'SERVICE',
  action: Action.CREATE
};
export const PROVIDER_UPDATE_SERVICE = {
  entity: 'SERVICE',
  action: Action.UPDATE
};
export const PROVIDER_REORDER_SERVICE = {
  entity: 'SERVICE',
  action: Action.REORDER
};
export const PROVIDER_RESTORE_SERVICE = {
  entity: 'PROVIDER_RESTORE_SERVICE',
  action: Action.UPDATE
};
export const PROVIDER_SERVICES_COURSES_EVENTS = {
  entity: 'PROVIDER_SERVICES_COURSES_EVENTS',
  action: Action.GET_MANY
};
export const PROVIDER_SERVICES_SUBSERVICES_CLASSES = {
  entity: 'PROVIDER_SERVICES_SUBSERVICES_CLASSES',
  action: Action.GET
};

// Actions
export const getPublicServices = (providerId, actionType) => async dispatch => execute(dispatch, () => getPublicServicesApiCall({
  providerId
}), actionType || SERVICES_GET_MANY);
export const getAllServices = (providerId, actionType) => async dispatch => execute(dispatch, () => getAllServicesApiCall({
  providerId
}), actionType);
export const getServices = (type, silent) => async dispatch => {
  let serviceType = ServiceTypes.service;
  if (type === EntityTypes.course) {
    serviceType = ServiceTypes.course;
  } else if (type === EntityTypes.event) {
    serviceType = ServiceTypes.event;
  }
  return execute(dispatch, () => getServicesApiCall({
    type: serviceType
  }, {
    group: ['id', 'name', 'color'],
    resources: ['id', 'name', 'userId'],
    materialResources: ['id', 'name']
  }), PROVIDER_SERVICES_GET_MANY, false, null, silent);
};
export const getProviderAllServices = (type, silent) => async dispatch => {
  let serviceType = ServiceTypes.service;
  if (type === EntityTypes.course) {
    serviceType = ServiceTypes.course;
  } else if (type === EntityTypes.event) {
    serviceType = ServiceTypes.event;
  }
  return execute(dispatch, () => getProviderAllServicesApiCall({
    type: serviceType
  }), PROVIDER_SERVICES_GET_MANY_ALL, false, null, silent);
};
export const getServicesCoursesEvents = () => async dispatch => {
  return execute(dispatch, () => getServicesCoursesEventsApiCall(), PROVIDER_SERVICES_COURSES_EVENTS);
};
export const getServicesSubServicesClasses = () => async dispatch => {
  return execute(dispatch, () => getServicesSubServicesClassesApiRequest(), PROVIDER_SERVICES_SUBSERVICES_CLASSES);
};
export const getArchivedServices = (type, silent) => async dispatch => {
  let serviceType = ServiceTypes.service;
  if (type === EntityTypes.course) {
    serviceType = ServiceTypes.course;
  } else if (type === EntityTypes.event) {
    serviceType = ServiceTypes.event;
  }
  return execute(dispatch, () => getArchivedServicesApiCall({
    type: serviceType
  }), PROVIDER_ARCHIVED_SERVICES_GET_MANY, false, null, silent);
};
export const saveService = data => async dispatch => {
  const {
    id,
    svcGroupId,
    svcTypeId,
    price,
    resources,
    duration,
    capacity,
    startTime,
    endTime,
    startDate,
    endDate,
    defaultTimeSlotStep,
    applyTimeSlotToAllSubservices,
    materialResources,
    imageId,
    preparationTime,
    credits,
    ...rest
  } = data;
  const imageUUID = imageId || null;
  const request = {
    ...rest,
    id: +id,
    svcGroupId: +svcGroupId,
    svcTypeId: +svcTypeId,
    price: +price,
    resources: Array.isArray(resources) ? resources.map(x => +x) : [+resources],
    duration: +duration,
    capacity: +capacity,
    defaultTimeSlotStep: +defaultTimeSlotStep,
    applyTimeSlotToAllSubservices: !!applyTimeSlotToAllSubservices,
    materialResources: Array.isArray(materialResources) ? materialResources.map(x => +x) : [+materialResources],
    imageId: imageUUID,
    preparationTime: +preparationTime || 0,
    credits: credits ? +credits : 1
  };
  if (startTime && endTime) {
    request.startTime = "".concat(startDate || DEFAULT_START_DATE, "T").concat(startTime, ":00.000Z");
    request.endTime = "".concat(endDate || DEFAULT_END_DATE, "T").concat(endTime, ":00.000Z");
  } else if (startDate && endDate) {
    request.startTime = "".concat(startDate, "T").concat(DEFAULT_START_TIME, ".000Z");
    request.endTime = "".concat(endDate, "T").concat(DEFAULT_END_TIME, ".000Z");
  }
  return execute(dispatch, () => saveServiceApiCall({
    request
  }), id ? PROVIDER_UPDATE_SERVICE : PROVIDER_ADD_SERVICE, true);
};
export const toggleServiceStatus = (id, status, type) => async dispatch => execute(dispatch, () => toggleServiceStatusApiCall({
  id,
  status,
  type
}), PROVIDER_SERVICE_TOGGLE_STATUS, true);
export const servicePublishAllChildren = (id, type) => async dispatch => execute(dispatch, () => publishAllServiceChildrenApiCall({
  id,
  type
}), PROVIDER_SERVICE_PUBLISH_ALL_CHILDREN, true);
export const resetServiceState = () => async dispatch => {
  dispatch(resetState(PROVIDER_SERVICES_GET_MANY));
  dispatch(resetState(SERVICES_GET_MANY));
  dispatch(resetState(PROVIDER_ADD_SERVICE));
  dispatch(resetState(PROVIDER_UPDATE_SERVICE));
};
export const reorderServices = (type, oldPosition, newPosition) => async dispatch => {
  execute(dispatch, () => reorderServicesApiCall({
    type,
    oldPosition,
    newPosition
  }), PROVIDER_REORDER_SERVICE, false, null, true);
};
export const archiveService = id => async dispatch => execute(dispatch, () => archiveServiceApiCall({
  id
}), PROVIDER_ARCHIVE_SERVICE, true);
export const restoreService = id => async dispatch => execute(dispatch, () => restoreServiceApiCall({
  id
}), PROVIDER_RESTORE_SERVICE);

// Reducers
export const serviceReducers = {
  all: basicReducer(PROVIDER_SERVICES_GET_MANY),
  active: basicReducer(SERVICES_GET_MANY),
  create: basicReducer(PROVIDER_ADD_SERVICE),
  update: basicReducer(PROVIDER_UPDATE_SERVICE),
  toggle: basicReducer(PROVIDER_SERVICE_TOGGLE_STATUS),
  reorder: basicReducer(PROVIDER_REORDER_SERVICE),
  publishAll: basicReducer(PROVIDER_SERVICE_PUBLISH_ALL_CHILDREN),
  delete: basicReducer(PROVIDER_ARCHIVE_SERVICE),
  archived: basicReducer(PROVIDER_ARCHIVED_SERVICES_GET_MANY),
  restore: basicReducer(PROVIDER_RESTORE_SERVICE),
  archivedAndNonArchived: basicReducer(PROVIDER_SERVICES_GET_MANY_ALL),
  servicesCoursesEvents: basicReducer(PROVIDER_SERVICES_COURSES_EVENTS),
  servicesSubServicesClasses: basicReducer(PROVIDER_SERVICES_SUBSERVICES_CLASSES)
};